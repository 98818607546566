<template>
  <div v-bind:style="styles" id="Pgwrp" ref="Pgwrp">
    <div id="page-content">
      <div class="webNew-dispflex">
       
       <!-- <div class="backbox">
          <div class="back-arrow" @click="previousRoute">
            <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
          </div>
       </div> -->
       <h1 class="hdrtitle backHeading" style="margin-top: 0px;">
        Attendance Report
       </h1>
     </div>
      <!-- <div class="dispflex">
        <h1 class="hdrtitle" style="margin-top: 0px">Attendance Report</h1>
        <button type="button" class="backbtn" @click="previousRoute">
          <i class="fa fa-arrow-left"></i>
        </button>
      </div> -->

      <div class="row">
        <div class="col-lg-12">
          <div class="widjet">
            <div class="p-3 widjetcontent">
              <div class="tabs" style="height: 2%; background: none">
                <input
                  v-if="userDetail.profile.name != 'Teacher'"
                  type="radio"
                  name="tabs"
                  id="staffAttendance"
                  :checked="
                    userDetail.profile.name != 'Teacher' && isChecked == false ? 'checked' : ''
                  "
                />
                <label
                  for="staffAttendance" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;"
                  v-if="userDetail.profile.name != 'Teacher'"
                  >Staff Attendance</label
                >
                <div class="tab" v-if="userDetail.profile.name != 'Teacher'">
                  <div class="row" style="margin-top: 1%">
                    <div class="col-lg-4">
                      <span class="has-float-label">
                        <v-select
                          placeholder="Type Staff Name"
                          class="form-control"
                          label="label"
                          v-model="staffName"
                          :options="optionStaffList"
                          @search="getStaffList"
                          :reduce="(label) => label.value"
                          @input="selectedOption"
                        >
                          <span
                            v-if="!showOption"
                            style="display: none !important"
                            slot="no-options"
                          ></span>
                        </v-select>
                        <!-- v-model="sname"
                          :options="accountList"
                          @search="getSchoolList"
                          @input="selectedOption" -->
                        <!-- <label for="clslistid"
                          >Search Staff Name</label
                        > -->
                      </span>
                    </div>
                    <div class="col-lg-4" style="margin-top: 7px;">
                      <span class="has-float-label">
                        <select
                          class="form-control form-input"
                          id="typeList"
                          :disabled="!staffName"
                          v-model="selectedMonth"
                          @change="
                            getSelectedMonthAttendanceList(selectedMonth)
                          "
                        >
                          <option :value="null">-- Select --</option>

                          <option
                            v-for="(item, index) in previousMonths"
                            :key="index"
                            :value="item.value"
                          >
                            {{ item.label }}
                          </option>
                        </select>
                        <label for="clslistid">Select Month </label>
                      </span>
                    </div>
                    <table border="1" class="table text-center" style="margin: 5px;
    border: 7px;">
                      <thead>
                        <tr style="border: 1px;background: rgb(227, 236, 255);" >
                          <th scope="col" style="text-align: center">Day</th>
                          <th scope="col" style="text-align: center">Date</th>
                          <th scope="col" style="text-align: center">
                            Clock In Time
                          </th>
                          <th scope="col" style="text-align: center">
                            Clock Out Time
                          </th>
                          <th scope="col" style="text-align: center">
                            Duration
                          </th>
                          <th scope="col" style="text-align: center">Status</th>
                          <th scope="col" style="text-align: center">Action</th>
                        </tr>
                      </thead>
                      <tbody v-if="staffName && selectedMonth">
                        <tr v-for="(data, index) in dates" :key="index">
                          <td :style="!checkAvaiable(data.day) ? 'cursor: not-allowed;background-color: #e3ecff;color: darkgray;text-align:center' : 'text-align:center'">{{ data.day }}</td>
                          <td :style="!checkAvaiable(data.day) ? 'cursor: not-allowed;background-color: #e3ecff;color: darkgray;text-align:center' : 'text-align:center'">
                            {{ data.createdDate }}
                          </td>
                          <!-- <td style="text-align:center">{{ new Date() }}</td> -->
                          <!-- <td style="text-align:center">{{ new Date() | dateTimeFormat }}</td> -->

                          <td :style="!checkAvaiable(data.day) ? 'cursor: not-allowed;background-color: #e3ecff;color: darkgray;text-align:center' : 'text-align:center'">
                            {{
                              getTimeValuesByClockInDate(
                                attendanceList,
                                data.createdDate
                              )
                                ? getTimeValuesByClockInDate(
                                    attendanceList,
                                    data.createdDate
                                  )
                                : "-"
                            }}
                          </td>

                          <td :style="!checkAvaiable(data.day) ? 'cursor: not-allowed;background-color: #e3ecff;color: darkgray;text-align:center' : 'text-align:center'">
                            {{
                              getTimeValuesByClockOutDate(
                                attendanceList,
                                data.createdDate
                              )
                                ? getTimeValuesByClockOutDate(
                                    attendanceList,
                                    data.createdDate
                                  )
                                : "-"
                            }}
                          </td>
                          <td
                          :style="!checkAvaiable(data.day) ? 'cursor: not-allowed;background-color: #e3ecff;color: darkgray;text-align:center' : 'text-align:center'"
                            v-if="
                              getTimeValuesByClockInDate(
                                attendanceList,
                                data.createdDate
                              ) &&
                              getTimeValuesByClockOutDate(
                                attendanceList,
                                data.createdDate
                              )
                            "
                          >
                            {{
                              getTimeValuesByDurationDate(
                                getTimeDurationByClockInDate(
                                  attendanceList,
                                  data.createdDate
                                ),
                                getTimeDurationByClockOutDate(
                                  attendanceList,
                                  data.createdDate
                                )
                              )
                            }}
                          </td>
                          <td :style="!checkAvaiable(data.day) ? 'cursor: not-allowed;background-color: #e3ecff;color: darkgray;text-align:center' : 'text-align:center'" v-else>-</td>
                          
                          <td
                          :style="!checkAvaiable(data.day) ? 'cursor: not-allowed;background-color: #e3ecff;color: darkgray;text-align:center' : getStatusByClockInDate(attendanceList,data.createdDate) == 'Present' ? 'text-align:center;color: #0aed06;' : getStatusByClockInDate(attendanceList,data.createdDate ) == 'Absent' ? 'text-align:center;color: red;' : 'text-align:center;'"
                           
                          >
                            {{
                              getStatusByClockInDate(
                                attendanceList,
                                data.createdDate
                              )
                            }}
                          </td>
                          <!-- <td style="text-align:center">{{ getStatusByClockInDate(attendanceList,data.createdDate) }}</td> -->

                          <td :style="!checkAvaiable(data.day) ? 'cursor: not-allowed;background-color: #e3ecff;color: darkgray;text-align:center' : 'text-align:center'">
                            <button class="btn btnsml" style="margin-right: 3%;border: 1px solid #ced4da;border-radius: 1.25rem;width: 115px;"
                             
                              :disabled="
                                getStatusByClockInDate(
                                  attendanceList,
                                  data.createdDate
                                ) == 'Pending Approval' ||
                                getStatusByClockInDate(
                                  attendanceList,
                                  data.createdDate
                                ) == 'Leave' || !checkAvaiable(data.day)
                              "
                              @click.prevent="openModal(data)"
                            >
                              Regularize
                            </button>
                            <button
                              :style="
                                getStatusByClockInDate(
                                  attendanceList,
                                  data.createdDate
                                ) == 'Leave' ||
                                getStatusByClockInDate(
                                  attendanceList,
                                  data.createdDate
                                ) == 'Absent'
                                  ? 'background-color: darkgray;'
                                  : '' || !checkAvaiable(data.day)
                              "
                              :disabled="
                                getStatusByClockInDate(
                                  attendanceList,
                                  data.createdDate
                                ) == 'Leave' ||
                                getStatusByClockInDate(
                                  attendanceList,
                                  data.createdDate
                                ) == 'Absent' || !checkAvaiable(data.day)
                              "
                              class="btn round red tooltipt"
                              @click="showDeleteModal(data)"
                            >
                              <i class="far fa-trash-alt"></i>
                              <span class="tooltiptext">Delete</span>
                            </button>
                            <!-- <button :disabled="getStatusByClockInDate(attendanceList,data.createdDate) == 'Leave' " @click.prevent="openModal(data)">Regularize</button> -->
                          </td>
                        </tr>
                      </tbody>
                      <tbody v-else>
                        <tr style="text-align: center">
                          <td style="text-align: center">-</td>
                          <td style="text-align: center">-</td>
                          <td style="text-align: center">-</td>
                          <td style="text-align: center">-</td>
                          <td style="text-align: center">-</td>
                          <td style="text-align: center">-</td>
                          <td style="text-align: center">-</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <!-- <input
                  type="radio"
                  name="tabs"
                  :checked="
                    userDetail.profile.name == 'Teacher' ? 'checked' : ''
                  "
                  id="studentSttendance"
                />
                <label for="studentSttendance">Enroll Student Attendance</label>
                <div class="tab">
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="widjet">
                        <div
                          class="widjethdr dispflex"
                          style="padding-bottom: 0px"
                        >
                          <span class="has-float-label">
                            <select
                              class="form-control form-input"
                              id="deptlistid"
                              v-model="selectClass"
                              @change="getSection(selectClass)"
                            >
                              <option :value="null">-- Select --</option>
                              <option
                                v-for="(item, index) in classList"
                                :key="index"
                                :value="item._id"
                              >
                                {{ item.className }}
                              </option>
                            </select>
                            <label for="clslistid">Select Class </label>
                          </span>
                          <span class="has-float-label" style="width: 11%">
                            <select
                              :disabled="!selectClass"
                              @change="checkIsAdminTeacher(selectSection)"
                              class="form-control form-input"
                              id="deptlistid"
                              v-model="selectSection"
                            >
                              <option :value="null">-- Select --</option>

                              <option
                                v-for="(item, index) in sectionList"
                                :key="index"
                                :value="item._id"
                              >
                                {{ item.name }}
                              </option>
                            </select>
                            <label for="clslistid">Select Classroom </label>
                          </span>
                          <span class="has-float-label" style="width: 11%">
                            <input
                              type="date"
                              @change="getStudentAttendanceList"
                              :disabled="!selectClass || !selectSection"
                              v-model="selectDate"
                              class="form-control form-input"
                              placeholder="Search Students"
                            />

                            <label for="clslistid">Select Date </label>
                          </span>

                        
                          <div style="margin-top: 10px" class="flexitm">
                          </div>

                          <div class="text-right dk_iconsml widjetcontent">
                          
                            <button
                              v-if="!isDailyAttendance"
                              style="width: 100%; margin-left: 6px"
                              type="button"
                              
                              @click="updateDailyAttendance"
                              class="btn btnsml"
                              id="svbtn"
                            >
                              <span>Update</span>
                            </button>
                            <button
                              v-if="isDailyAttendance"
                              style="width: 100%; margin-left: 6px"
                              type="button"
                             
                              @click.prevent="generateandApprove"
                              class="btn btnsml"
                              id="svbtn"
                            >
                              <span>Save</span>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row" v-if="!isDailyAttendance">
                    <div class="col-lg-12">
                      <div class="widjet">
                        <div class="widjetcontent">
                          <div
                            class="countsect p-2 mt-3 mb-3"
                            style="border-radius: 0rem"
                          >
                            <ul class="dispflex">
                              <li>
                                <span class="badge contbadge">{{
                                  studentAttendanceList.length
                                }}</span
                                >Total Students
                              </li>
                              <li>
                                <span class="badge contbadge">{{
                                  getPresentCount(studentAttendanceList)
                                }}</span
                                >Present
                              </li>
                              <li>
                                <span class="badge contbadge">{{
                                  getAbsentCount(studentAttendanceList)
                                }}</span
                                >Absent
                              </li>
                             
                            </ul>
                          </div>
                          <div>
                            <div class="restable">
                              <div class="resrow resheader">
                                <div class="cell">Student Name</div>

                                <div class="cell">Status</div>
                                <div class="cell">Leave</div>

                              </div>
                              <div
                                class="resrow"
                                v-for="(
                                  student, index
                                ) in studentAttendanceList"
                                :key="index"
                              >

                                <div class="cell" data-title="Student Name">
                                  {{ student.firstName + " " + student.lastName }}
                                 
                                </div>

                                <div
                                  class="cell"
                                  data-title="Student Name"
                                  :style="
                                    student.status == 'Present'
                                      ? 'color: lawngreen;'
                                      : 'color: red;'
                                  "
                                >
                                 
                                  <div class="cell">
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              v-model="student.isPresent"
                            />
                            <div class="slider"></div>
                          </label>
                        </div>
                                
                                </div>
                                <div
                                  class="cell"
                                  data-title="Student Name"
                                 
                                >
                                {{  student.status == 'Leave' ? 'Yes' : '-'}}
                                </div>
                                

                             
                              </div>
                            </div>
                          </div>
                          <div
                            class="crtfeeplancont"
                            style="padding: 0 13px"
                            v-if="studentAttendanceList.length == 0"
                          >
                            <div style="margin-top: 10%; text-align: center">
                              No Data Found
                            </div>
                          </div>
                        
                        </div>
                      
                     
                      </div>
                    </div>
                  </div>
                  <div v-if="isDailyAttendance">
          <form id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group col-md-12 mt-3 pr-md-3">
                <div class="widjetcontent tbllist mt-4">
                  <div class="restable">
                    <div class="resrow resheader">
                      <div class="cell">Student Name</div>

                      <div class="cell">Date</div>
                      <div class="cell">Present/Absent</div>
                    </div>
                    <div
                      class="resrow"
                      v-for="(data, index) in studentAttendanceDetails"
                      :key="index"
                    >
                      <div class="cell" data-title="Teacher">
                        {{ data.firstName + " " + data.lastName }}
                      </div>
                      <div class="cell" data-title="Teacher">
                        {{ data.attendanceDate }}
                      </div>
                      <div class="cell" data-title="Teacher">
                        <div class="cell">
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              v-model="data.isPresent"
                            />
                            <div class="slider"></div>
                          </label>
                        </div>

                      
                      </div>

                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="dispflex mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <div class="dk_icon"></div>

              <div class="text-right dk_iconsml">
               
              </div>
            </div>
          </form>
        </div>
                 
                </div> -->
                <input
                  type="radio"
                  name="tabs"
                  :checked="
                    userDetail.profile.name == 'Teacher' ? 'checked' : isChecked ? 'checked' : ''
                  "
                  id="overAllStudentSttendance"
                />
                <label for="overAllStudentSttendance" style="border: 1px solid rgb(192, 196, 233);border-radius: 15px 15px 0px 0px;">Student Attendance Report</label>
                <div class="tab">
                  <div class="row" style="margin-bottom: 10px;">
                    <div class="col-lg-12">
                      <div class="widjet">
                        <div
                          class="widjethdr dispflex"
                          style="padding-bottom: 0px;border-bottom: 0px solid #efefef;"
                        >
                        <span class="has-float-label" style="width: 11%">
                            <select
                          class="form-control form-input"
                          id="typeList"
                          v-model="overallSelectMonth"
                          @change="getOverallAttendanceList"
                        >
                          <option :value="null">-- Select --</option>

                          <option
                            v-for="(item, index) in overAllPreviousMonths"
                            :key="index"
                            :value="item.value"
                          >
                            {{ item.label }}
                          </option>
                        </select>

                            <label for="clslistid">Select Month </label>
                          </span>
                          <span class="has-float-label">
                            <select
                              class="form-control form-input"
                              id="deptlistid"
                              v-model="overallSelectedClass"
                              @change="getOverAllSection(overallSelectedClass)"
                            >
                              <option :value="null">-- Select --</option>
                              <option
                                v-for="(item, index) in overallClassList"
                                :key="index"
                                :value="item._id"
                              >
                                {{ item.className }}
                              </option>
                            </select>
                            <label for="clslistid">Select Class </label>
                          </span>
                          <span class="has-float-label" style="width: 13%">
                            <select
                             :disabled="!overallSelectedClass"
                              @change="getOverallAttendanceList"
                              class="form-control form-input"
                              id="deptlistid"
                              v-model="overallSelectedSection"
                            >
                              <option :value="null">-- Select --</option>

                              <option
                                v-for="(item, index) in overallSectionList"
                                :key="index"
                                :value="item._id"
                              >
                                {{ item.name }}
                              </option>
                            </select>
                            <label for="clslistid">Select Classroom </label>
                          </span>
                          <!-- <span class="has-float-label" style="width: 11%">
                            <select
                            
                              class="form-control form-input"
                              id="deptlistid"
                              v-model="selectedStatus"
                            >
                              <option :value="null">-- Select --</option>

                              <option
                                v-for="(item, index) in statusList"
                                :key="index"
                                :value="item"
                              >
                                {{ item }}
                              </option>
                            </select>
                            <label for="clslistid">Select Status </label>
                          </span> -->
                         
                          <span class="has-float-label">
                            <input type="text" style="margin-top: 0px" class="form-control form-input"  v-model="searchWords" >

                            <label for="clslistid" >Search Student </label>
                          </span>
                          <!-- <div style="margin-top: 5px" class="flexitm">
                            <input type="text" class="form-control form-input" v-model="searchWords" placeholder="Search Students">

                          </div> -->

                          <div class="text-right dk_iconsml widjetcontent">
                           
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-lg-12">
                      <div class="widjet">
                        <div class="widjetcontent">
                        
                          <div>
                            <div class="restable">
                              <div class="resrow resheader">
                                <div class="cell">Student Name</div>
                                <div class="cell" style="text-align: center;">Class</div>
                                <div class="cell" style="text-align: center;">Classroom</div>
                                <div class="cell" style="text-align: center;">Total Days</div>
                                <div class="cell" style="text-align: center;">Total Present</div>
                                <div class="cell" style="text-align: center;">Total Absent</div>

                              </div>
                              <div
                                class="resrow"
                                v-for="(
                                  student, index
                                ) in Object.keys(overallAttendanceList)"
                                :key="index"
                                
                              >
                              <!-- v-if="(index + 1) <= showLimitCount" -->
                              <div class="cell" data-title="Student Name">
                                {{ overallAttendanceList[student][0].firstName + " " +  overallAttendanceList[student][0].lastName}}
                                <!-- <a style="padding-left: 6px;" href="javascript:void(0);"  @click.prevent="redirectRoute(`/admission/student/view/details/${student._id}`)">{{ overallAttendanceList[student][0].firstName + " " +  overallAttendanceList[student][0].lastName}} </a> -->

                                </div>

                                <div class="cell" data-title="Student Name" style="text-align: center;">{{ overallAttendanceList[student][0].class ? overallAttendanceList[student][0].class.className : '' }}</div>

                                <div class="cell" data-title="Student Name" style="text-align: center;">{{ overallAttendanceList[student][0].section ? overallAttendanceList[student][0].section.name : '' }}</div>
                                <div class="cell" data-title="Student Name" style="text-align: center;">{{ getTotalDays(student) }}</div>

                                <div class="cell" data-title="Student Name" style="text-align: center;">{{ getTotalPresent(student)}}</div>
                                <div class="cell" data-title="Student Name" style="text-align: center;">{{ getTotalAbsent(student)}}</div>

                               
                              </div>
                            </div>
                          </div>
                          <div
                            class="crtfeeplancont"
                            style="padding: 0 13px"
                            v-if="Object.keys(overallAttendanceList).length == 0"
                          >
                            <div style="margin-top: 10%; text-align: center">
                              No Data Found
                            </div>
                          </div>
                        
                        </div>
                        <div class="text-center mt-4" v-if="Object.keys(overallAttendanceList).length > 0">
                <div class="pagination" id="pagination">
                  <!-- <a  :style="currentPageNumber === 1 ? 'pointer-events: none;' : ''" @click="goToPreviousPage">&laquo;</a> -->
                  <a
                    v-for="(item, index) in lastPageNumber"
                    :key="index"
                    v-on:click="selected = item"
                    v-bind:class="{ active: selected == item }"
                    @click="navToSelectedPage(item)"
                    >{{ item }}</a
                  >
                  <!-- <a  :style="currentPageNumber === lastPageNumber ? 'pointer-events: none;' : ''" @click="goToNextPage">&raquo;</a> -->
                </div>
            </div>
                      
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <vi-spinner
      v-if="viLoader"
      text="Loading..."
      textColor="vi-brand-color"
      class="flex-fill h-100 vi-fs12"
      style="
        width: 100%;
        z-index: 111;
        top: 0;
        left: 0;
        background-color: rgba(255, 255, 255, 0.5);
        border-radius: 1rem;
      "
    /> -->
    <b-modal id="deletePopup">
      <button type="button" class="close"  @click.prevent="closeDeletePopUp">×</button>

      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Delete Confirmation</h2>
        <br />
        Are you sure you want to delete this record?
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="confirmDelete"
          >
              <span>Confirm</span>
          </button>
          <button
            @click.prevent="closeDeletePopUp"
            type="button"
            class="btn btncl clsmdl"
            id="clbtn"
          >
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
    <b-modal id="modalOne" content-class="md_content" class="modal">
      <button
        style="
          border: 0;
          background: transparent;
          position: absolute;
          top: 10px;
          right: 10px;
        "
        @click.prevent="closeAttendance"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>

      <div class="row">
        <div class="col-md-12">
          <div>
            <h4>Attendance Details</h4>
          </div>

          <div>
            <form
              data-vv-scope="attendance"
              id="attendance-fomr"
              accept-charset="utf-8"
            >
              <div class="form-row">
                <!-- <div
                  class="form-group input-group col-12 mt-3"
                  style="border-bottom: 1px solid #e5e5e5"
                >
                  <span style="color: #6c757d" class="mr-3"
                    >Is Absent? <span class="required">*</span></span
                  >
                  <label class="switch">
                    <input
                      id="togBtn"
                      type="checkbox"
                      v-model="attendance.isAbsent"
                      @change="absentChange"
                    />
                    <div class="slider"></div>
                  </label>
                </div> -->

                <div class="form-group input-group col-12 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <input
                      type="datetime-local"
                      :min="minimumDate"
                      :max="minimumDate"
                      name="clkin"
                      v-model="attendance.clockInTime"
                      class="form-control form-input"
                    />

                    <label for="clockInTime"
                      >Clock In Time <span class="required">*</span></label
                    >
                  </span>
                </div>

                <div class="form-group input-group col-12 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <input
                      type="datetime-local"
                      name="clockOutTime"
                      :min="minimumDate"
                      :max="minimumDate"
                      v-model="attendance.clockOutTime"
                      class="form-control form-input"
                    />
                    <label for="clockOutTime">
                      Clock Out Time <span class="required">*</span></label
                    >
                  </span>
                </div>

                <div class="form-group input-group col-12 mt-md-3">
                  <span class="has-float-label">
                    <textarea
                      id="notes"
                      class="form-control form-input"
                      placeholder="Notes"
                      style="min-height: 90px; resize: none"
                      v-model="attendance.notes"
                    ></textarea>
                    <label for="notes"
                      >Notes <span class="required">*</span></label
                    >
                  </span>
                </div>
              </div>
            </form>
          </div>

          <div class="text-right">
            <div class="dk_iconsml">
              <button
                id="updbtn"
                type="button"
                :disabled="
                  !attendance.clockInTime ||
                  !attendance.clockOutTime ||
                  !attendance.notes
                "
                class="btn btnsml"
                @click.prevent="updateAttendance"
              >
                  <span>Save</span>
              </button>

              <button
                id="clbtn"
                type="button"
                class="btn btncl"
                @click.prevent="closeAttendance"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <b-modal id="addAttendancePopUp" style="width: 100%">
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Add Attendance</h2>
        <br />
        <div>
          <form id="stuprofile" accept-charset="utf-8">
            <div class="form-row">
              <div class="form-group col-md-12 mt-3 pr-md-3">
                <div class="tbllist mt-4">
                  <div class="restable">
                    <div class="resrow resheader">
                      <div class="cell">Student Name</div>

                      <div class="cell">Date</div>
                      <div class="cell">Present/Absent</div>
                      <!-- <div class="cell">Action</div> -->
                    </div>
                    <div
                      class="resrow"
                      v-for="(data, index) in studentAttendanceDetails"
                      :key="index"
                    >
                      <div class="cell" data-title="Teacher">
                        {{ data.firstName + " " + data.lastName }}
                      </div>
                      <div class="cell" data-title="Teacher">
                        {{ data.attendanceDate }}
                      </div>
                      <div class="cell" data-title="Teacher">
                        <div class="cell">
                          <label class="switch">
                            <input
                              type="checkbox"
                              id="togBtn"
                              v-model="data.isPresent"
                            />
                            <div class="slider"></div>
                          </label>
                        </div>

                        <!-- <select
                          class="form-control form-input"
                          v-model="data.status"
                          :disabled="data.status == 'Leave' && data._id"
                        >
                          <option :value="null">--Select--</option>
                          <option
                            v-for="(item, index) in attendanceStatus"
                            :key="index"
                            :value="item"
                          >
                            {{ item }}
                          </option>
                        </select> -->
                        <!-- {{ data.isAbsent ? "Present" : "Absent" }} -->
                      </div>

                      <!-- <div class="cell" data-title="Teacher">
                        <button
                          @click.prevent="confirmDelete(data._id, data.index)"
                          class="btn round red tooltipt"
                        >
                          <i class="far fa-trash-alt"></i>
                          <span class="tooltiptext">Delete</span>
                        </button>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              class="mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <!-- <div class="dk_icon"></div> -->

              <div class="text-center dk_iconsml">
                <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="generateandApprove"
                >
                    <span>Save</span>
                </button>
                <button
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                  @click="closeAttendancePopUp"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="updateModel"
      style="width: 100%"
      no-close-on-backdrop
      no-close-on-esc
    >
    <button type="button" class="close"  @click.prevent="closeUpdateModel">×</button>
      <div class="contact-form">
        <h2>Update Student Attendance</h2>

        <br />
        <div>
          <form
            data-vv-scope="academicyr"
            id="academicyr"
            accept-charset="utf-8"
          >
            <div class="form-row mt-2">
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="text"
                    disabled
                    name="Religion"
                    v-model="updateAttendanceDetails.firstName"
                    class="form-control form-input"
                    id="relid"
                  />
                  <label for="relid">Student Name </label>
                </span>
              </div>
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    class="form-control form-input"
                    id="deptlistid"
                    disabled
                    v-model="selectClass"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in classList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.className }}
                    </option>
                  </select>
                  <label for="relid">Class </label>
                </span>
              </div>
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    disabled
                    class="form-control form-input"
                    id="deptlistid"
                    v-model="selectSection"
                  >
                    <option :value="null">-- Select --</option>

                    <option
                      v-for="(item, index) in sectionList"
                      :key="index"
                      :value="item._id"
                    >
                      {{ item.name }}
                    </option>
                  </select>

                  <label for="relid">Classroom </label>
                </span>
              </div>
              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <input
                    type="date"
                    disabled
                    v-model="selectDate"
                    class="form-control form-input"
                    placeholder="Search Students"
                  />
                  <label for="relid">Attendance Date </label>
                </span>
              </div>

              <div class="form-group input-group col-md-4 pr-md-3 mt-3">
                <span class="has-float-label">
                  <select
                    name="gender"
                    class="form-control form-input"
                    id="gender"
                    v-model="updateAttendanceDetails.status"
                  >
                    <option :value="null">-- Select --</option>
                    <option
                      v-for="(item, index) in attendanceStatus"
                      :key="index"
                      :value="item"
                    >
                      {{ item }}
                    </option>
                  </select>
                  <label for="relid">Status </label>
                </span>
              </div>
            </div>
            <div
              class="mt-3 pt-3"
              style="border-top: 1px solid #e5e5e5"
            >
              <!-- <div class="dk_icon"></div> -->
              <div class="text-center dk_iconsml">
                <button
                  type="button"
                  class="btn btnsml"
                  id="svbtn"
                  @click.prevent="updateStudentAttendance"
                >
                    <span>Update</span>
                </button>
                <button
                  type="button"
                  class="btn btncl clsmdl"
                  id="clbtn"
                  @click.prevent="closeUpdateModel"
                >
                     <span>Cancel</span>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>
  </div>
</template>
<script>
import ViSpinner from "./Common/ViSpinner";
import Select from "vue-select";
import ViService from "@/services/ViService";
import secureUI from "../utils/secureUI";

export default {
  components: {
    ViSpinner,
    "v-select": Select,
  },
  data() {
    return {
      isAdmin: false,
      isChecked: false,
      userDetail: {},
      sectionList: [],
      overallSectionList: [],
      overallClassList: [],
      overAllPreviousMonths: [],
      overallSelectedClass: null,
      overallSelectedSection: null,
      overallSelectMonth: null,
      deleteId: null,
      previousMonths: [],
      minimumDate: null,
      selectedMonth: null,
      attendanceList: [],
      studentAttendanceDetails: [],
      showOption: false,
      staffName: "",
      staffList: [],
      studentAttendanceList: [],
      overallAttendanceList: {},
      selectedStaff: null,
      dates: [],
      skip: 0, // set the skip info
      limit: 5000, // set the limit info
      currentPageNumber: 1,
      lastPageNumber: 1,
      selected: 1,
      countAll: true,
      totalCount: [],
      minimumDate: null,
      attendance: {},
      attendanceStatus: ["Present", "Absent", "Leave"],
      statusList: ["Present", "Absent"],
      selectedStatus: null,
      selectedIndex: null,
      clockInTime: "",
      clockOutTime: "",
      selectClass: null,
      selectSection: null,
      selectDate: null,
      searchStudentName: "",
      classList: [],
      dayList: [],
      isDailyAttendance: false,
      studentCount: 0,
      updateAttendanceDetails: {},
      searchWords: '',
      showLimitCount: 30,
      allOverallAttendanceList: {}
    };
  },

  created() {
    this.initComponent();
    this.getPreviousMonths();
    this.formDateList();
    this.getClassWithSection();
    this.getAccountDetails();

  },
  watch: {
          searchWords: function (currentVal, oldVal) {
              //  let hasCurrentParam = currentVal || '';
              //  let hasOldParam = oldVal || '';

              // if (hasCurrentParam != hasOldParam) {
              //      this.skip = 0
              //      this.currentPageNumber = 1,
              //      this.lastPageNumber = 1,
              //      this.selected = 1,
              //      this.totalCount = [],
				      //      this.countAll = true,
                  this.getOverallAttendanceList();
             // }
          }
  },
  computed: {
    styles() {
      var brwHeight = window.innerHeight;
      return {
        height: brwHeight - 90 + "px",
      };
    },
    optionStaffList() {
      return this.staffList.map((x) => {
        return {
          // label: x.firstName + " " + x.lastName + " / " + x.userId,
          label: x.firstName + " " + x.lastName,
          value: x._id,
        };
      });
    },
  },
  methods: {
    async navToSelectedPage(selectedPageNo) {
    let start = null
    let end = null

    if(selectedPageNo != 1){

      let curent  = selectedPageNo + 1;
      let newStartNumber  = (this.showLimitCount * (curent - 1));
      start = (newStartNumber - this.showLimitCount)
      end = newStartNumber
    }else{
      start = 0
      end = this.showLimitCount
    }

    let dataLength = Object.entries(this.allOverallAttendanceList).length
    if(end > dataLength){
      end = dataLength
    }
    if(start >= dataLength){
      start = (start - 1)
    }
    
    var result = Object.entries(this.allOverallAttendanceList);
          
    let getCurrentPageData = result.slice(start, end)
    const obj = Object.fromEntries(getCurrentPageData);
    this.overallAttendanceList = obj
    },

    redirectRoute(vipath) {
      if (this.$route.path !== vipath) this.$router.push({ path: vipath });
    },
    getTotalPresent(data) {
      if (data) {
        let details = this.overallAttendanceList[data]
        let count = details.filter((x) => x.status == "Present");
        if (count) {
          return count.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    getTotalAbsent(data) {
      if (data) {
        let details = this.overallAttendanceList[data]
        let count = details.filter((x) => x.status == "Absent" || x.status == "Leave");
        if (count) {
          return count.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    getTotalDays(data) {
      if (data) {
        let details = this.overallAttendanceList[data]
      //  let count = details.filter((x) => x.status == "Absent" || x.status == "Leave");
        if (details) {
          return details.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    getTotalLeave(data) {
      if (data) {
        let details = this.overallAttendanceList[data]
        let count = details.filter((x) => x.status == "Leave");
        if (count) {
          return count.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    getPresentCount(data) {
      if (data.length > 0) {
        let count = data.filter((x) => x.status == "Present");
        if (count) {
          return count.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    getAbsentCount(data) {
      if (data.length > 0) {
        let count = data.filter((x) => x.status == "Absent" || x.status == "Leave");
        if (count) {
          return count.length;
        } else {
          return 0;
        }
      } else {
        return 0;
      }
    },
    initComponent() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {

          let queryDetails = this.$route.query
           
         if(queryDetails && queryDetails.fromType) {
          this.isChecked = true
         }

        this.userDetail = userData.userDetails;
      }
    },
    checkIsAdminTeacher(sectionId) {
      let userData = secureUI.sessionGet("user");

      if (userData.userDetails.profile.name == "Teacher") {
        let selectedSection = this.sectionList.find((x) => x._id == sectionId);

        if (selectedSection.assignedTeachers.length > 0) {
          let checkCreateAttendance = selectedSection.assignedTeachers.filter(
            (x) => x.teacherId == this.userDetail._id && x.isAdmin
          );

          if (checkCreateAttendance.length > 0) {
            this.isAdmin = true;
          } else {
            this.isAdmin = false;
          }
        } else {
          this.isAdmin = false;
        }
      } else {
        this.isAdmin = true;
      }

      this.selectDate = null;
      this.studentAttendanceList = []
    },
    getSection(classId) {
      this.selectSection = null;
      
       this.studentAttendanceList = []
    //  :disabled="!selectClass || !selectSection"

      //this.assignedSubjects = []
      let sections = this.classList.find((x) => x._id == classId).sections;

      let userData = secureUI.sessionGet("user");

      if (userData.userDetails.profile.name == "Teacher") {
        this.sectionList = sections.filter((x) => x.assignedTeachers.length > 0);
     
      } else {
        this.sectionList = sections;
      }

      if(this.sectionList.length > 0){
        this.selectSection = this.sectionList[0]._id
        this.checkIsAdminTeacher(this.selectSection)
        //this.getClassTerm()
       }

       if(!this.selectDate){
         let start = new Date()

        let startmonth = start.getMonth() + 1
        let startdate = start.getDate()
        let startYear = start.getFullYear()

        if (startdate < 10) {
          startdate = '0' + startdate;
        }
        if (startmonth < 10) {
          startmonth = '0' + startmonth;
        }
        this.selectDate = startYear + "-" + startmonth + "-" + startdate;
        this.getStudentAttendanceList()
       }
       

       
      
    },
    getOverAllSection(classId) {
      this.overallSelectedSection = null;
      this.overallSectionList = []
      let data = this.overallClassList.filter((x) => x._id == classId);
      if(data.length > 0){
        let userData = secureUI.sessionGet("user");

        if (userData.userDetails.profile.name == "Teacher") {
          this.overallSectionList = data[0].sections.filter((x) => x.assignedTeachers.length > 0);

        } else {
          this.overallSectionList = data[0].sections;
        }
      }
      
      this.getOverallAttendanceList()
    },
    
    async getClassWithSection() {
      let userData = secureUI.sessionGet("user");

      if (userData.userDetails.profile.name == "Teacher") {
        const response = await ViService.viXGet(
          "/teacher/getAssignedTeacherwithClassSectionList?viewType=list",
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          this.classList = resultData.data;
          this.overallClassList = resultData.data;
        } else {
          this.$toasted.error(response.message);
        }
      } else {
        const response = await ViService.viXGet(
          `/academic/class_with_sections`,
          userData.token
        );

        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          this.classList = resultData.data;
          this.overallClassList = resultData.data;
        } else {
          this.$toasted.error(response.message);
        }
      }

      if(this.classList.length > 0){
        this.selectClass = this.classList[0]._id
        await this.getSection(this.selectClass)
      }
    },
    openModal(data) {
      this.minimumDate = null;
      // this.maxmimumDate = null

      let tempAttendanceList = [...this.attendanceList];

      if (
        this.getTimeValuesByClockInDate(tempAttendanceList, data.createdDate)
      ) {
        const targetDateTime = new Date(data.createdDate).setHours(0, 0, 0, 0);

        for (const obj of tempAttendanceList) {
          const createdDateTime = new Date(obj.clockInTime).setHours(
            0,
            0,
            0,
            0
          );
          if (createdDateTime === targetDateTime) {
            this.attendance = obj;

            var clockInDate = new Date(this.attendance.clockInTime);
            var clockInYear = clockInDate.getFullYear();
            var clockInMonth = String(clockInDate.getMonth() + 1).padStart(
              2,
              "0"
            ); // Add 1 to month since it is zero-based
            var clockInDay = String(clockInDate.getDate()).padStart(2, "0");
            var clockInHour = String(clockInDate.getHours()).padStart(2, "0");
            var clockInMinute = String(clockInDate.getMinutes()).padStart(
              2,
              "0"
            );
            //var clockInSecond = String(clockInDate.getSeconds()).padStart(2, '0');

            this.attendance.clockInTime = `${clockInYear}-${clockInMonth}-${clockInDay}T${clockInHour}:${clockInMinute}`;

            if (this.attendance.clockOutTime) {
              var clockOutDate = new Date(this.attendance.clockOutTime);
              var clockOutYear = clockOutDate.getFullYear();
              var clockOutMonth = String(clockOutDate.getMonth() + 1).padStart(
                2,
                "0"
              ); // Add 1 to month since it is zero-based
              var clockOutDay = String(clockOutDate.getDate()).padStart(2, "0");
              var clockOutHour = String(clockOutDate.getHours()).padStart(
                2,
                "0"
              );
              var clockOutMinute = String(clockOutDate.getMinutes()).padStart(
                2,
                "0"
              );

              this.attendance.clockOutTime = `${clockOutYear}-${clockOutMonth}-${clockOutDay}T${clockOutHour}:${clockOutMinute}`;
              //  /// var clockOutFormattedDate = `${year}-${month}-${day}T${'20'}:${'00'}`;
              //  this.minimumDate = this.attendance.clockOutTime
            }
          }
        }

        //   const date = new Date(data.createdDate);
        //     const year = date.getFullYear();
        //     const month = String(date.getMonth() + 1).padStart(2, '0'); // Add 1 to month since it is zero-based
        //     const day = String(date.getDate()).padStart(2, '0');
        //   // var hours = String(date.getHours()).padStart(2, '0');
        //   //var minutes = String(date.getMinutes()).padStart(2, '0');
        //   const clockInFormattedDate = `${year}-${month}-${day}T${'00:59'}`;
        //  /// var clockOutFormattedDate = `${year}-${month}-${day}T${'20'}:${'00'}`;
        //   this.minimumDate = clockInFormattedDate
      } else {
        const date = new Date(data.createdDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, "0"); // Add 1 to month since it is zero-based
        const day = String(date.getDate()).padStart(2, "0");
        // var hours = String(date.getHours()).padStart(2, '0');
        //var minutes = String(date.getMinutes()).padStart(2, '0');
        const clockInFormattedDate = `${year}-${month}-${day}T${"00:59"}`;
        /// var clockOutFormattedDate = `${year}-${month}-${day}T${'20'}:${'00'}`;
        this.minimumDate = clockInFormattedDate;
      }


      this.$bvModal.show("modalOne");
    },
    cancelModal() {
      this.$bvModal.hide("modalOne");
    },
    async updateAttendance() {
      try {
        let isFormValid = false;

        await this.$validator.validateAll("modalOne").then((result) => {
          isFormValid = result;
        });

        if (isFormValid) {
          let userData = secureUI.sessionGet("user");

          if (!userData) {
            this.$toasted.error("Please login and do the action");
            this.$router.push("/login");
          } else {
            const response = await ViService.viXPut(
              `/user/attendance/regularize`,
              this.attendance,
              userData.token
            );
            if (response.isSuccess) {
              this.attendance = {};
              this.$bvModal.hide("modalOne");
              this.$toasted.success("Regularize Updated Successfully");
              this.getAttendanceList();
            } else {
              this.$toasted.error(response.message);
            }
          }
        }
      } catch (err) {
        this.$toasted.error(
          err && err.message ? err.message : "Internal Server Error"
        );
      }
    },
    async formDateList() {
      const currentDate = new Date();
      const firstDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      ); // Get the first day of the month

      this.currentMonth = currentDate.getMonth() + 1;

      while (currentDate >= firstDayOfMonth) {
        //  if(currentDate.getDay() == 0){
        const formattedDate = currentDate.toLocaleString("en-US", {
          weekday: "long",
          month: "long",
          day: "numeric",
        }); // Format the date as "Monday - May 29"

        const dayValue = currentDate.toLocaleString("en-US", {
          weekday: "long"
        }); // Format the date as "Monday - May 29"

        const currentYear = currentDate.getFullYear();
        const currentMonth = String(currentDate.getMonth() + 1).padStart(
          2,
          "0"
        ); // Months are zero-based, so we add 1
        const currentDay = String(currentDate.getDate()).padStart(2, "0");
        // if (new Date(formattedDate).getDay() != 1) {
          this.dates.push({
            day: dayValue || '',
            date: formattedDate,
            createdDate: `${currentYear}-${currentMonth}-${currentDay}`,
            clockInTime: "", // Replace with the actual clockInTime value
            clockOutTime: "", // Replace with the actual clockOutTime value
          }); // Add the formatted date and other data to the array
       // }

        currentDate.setDate(currentDate.getDate() - 1); // Decrement the date by one day
      }

      //}
    },
    async changeDateList(selectedMonth) {
      this.dates = []

      const currentDate = new Date();
      const currentYear = currentDate.getFullYear()
      const currentMonth = currentDate.getMonth() + 1
      const month = selectedMonth + 1
      if(month == currentMonth){
         this.formDateList()
      }else{
      const firstDayOfMonth = new Date(currentDate.getFullYear(),selectedMonth,1); // Get the first day of the month

      var lastDay = new Date(currentDate.getFullYear(),month, 0);

      while (lastDay >= firstDayOfMonth) {
        const formattedDate = lastDay.toLocaleString("en-US", {
          weekday: "long",
          month: "long",
          day: "numeric",
        }); // Format the date as "Monday - May 29"

        const dayValue = lastDay.toLocaleString("en-US", {
          weekday: "long"
        }); // Format the date as "Monday - May 29"


        const currentYear = lastDay.getFullYear();
        const currentMonth = String(lastDay.getMonth() + 1).padStart(
          2,
          "0"
        ); // Months are zero-based, so we add 1
        const currentDay = String(lastDay.getDate()).padStart(2, "0");
        //if (new Date(formattedDate).getDay() != 1) {
          this.dates.push({
            day: dayValue || '',
            date: formattedDate,
            createdDate: `${currentYear}-${currentMonth}-${currentDay}`,
            clockInTime: "", // Replace with the actual clockInTime value
            clockOutTime: "", // Replace with the actual clockOutTime value
          }); // Add the formatted date and other data to the array
       // }

        lastDay.setDate(lastDay.getDate() - 1); // Decrement the date by one day
      }

      }

      this.getStaffAttendanceList()
    },
    showDeleteModal(id) {
      this.deleteId = id;
      this.$bvModal.show("deletePopup");
    },
    showDeleteModal(data) {
      let tempAttendanceList = [...this.attendanceList];

      if (
        this.getTimeValuesByClockInDate(tempAttendanceList, data.createdDate)
      ) {
        const targetDateTime = new Date(data.createdDate).setHours(0, 0, 0, 0);

        for (const obj of tempAttendanceList) {
          const createdDateTime = new Date(obj.clockInTime).setHours(
            0,
            0,
            0,
            0
          );
          if (createdDateTime === targetDateTime) {
            this.deleteId = obj._id;
          }
        }
      }
      this.$bvModal.show("deletePopup");
    },
    closeDeletePopUp() {
      this.$bvModal.hide("deletePopup");
      this.deleteId = null;
    },
    getTimeValuesByClockInDate(allAttendenceList, targetDate) {
      const temp = [...allAttendenceList];

      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockInTime).setHours(0, 0, 0, 0);

        if (createdDateTime === targetDateTime) {
          const clockInDateTime = new Date(obj.clockInTime);
          if (
            obj.status == "Leave" ||
            (obj.status == "Pending Approval" &&
              obj.previousStatus == "Pending Approval")
          ) {
            return "";
          } else {
            return clockInDateTime.toLocaleTimeString();
          }
        }
        // else{
        //   return '-'
        // }
      }

      return "";
    },
    getTimeValuesByClockOutDate(allAttendenceList, targetDate) {
      const temp = [...allAttendenceList];

      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);

      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockOutTime).setHours(0, 0, 0, 0);

        if (createdDateTime === targetDateTime) {
          const clockOutDateTime = new Date(obj.clockOutTime);
          if (
            obj.status == "Leave" ||
            (obj.status == "Pending Approval" &&
              obj.previousStatus == "Pending Approval")
          ) {
            return "";
          } else {
            return clockOutDateTime.toLocaleTimeString();
          }
          // return clockOutDateTime.toLocaleTimeString();
        }
      }

      return "";
    },
    getTimeDurationByClockInDate(allAttendenceList, targetDate) {
      const temp = [...allAttendenceList];

      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockInTime).setHours(0, 0, 0, 0);
        if (createdDateTime === targetDateTime) {
          return new Date(obj.clockInTime);
          // return clockInDateTime.toLocaleTimeString();
        }
        // else{
        //   return '-'
        // }
      }

      return "";
    },
    getTimeDurationByClockOutDate(allAttendenceList, targetDate) {
      const temp = [...allAttendenceList];
      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);

      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockOutTime).setHours(0, 0, 0, 0);

        if (createdDateTime === targetDateTime) {
          return new Date(obj.clockOutTime);
          //return clockOutDateTime.toLocaleTimeString();
        }
        // else{
        //   return '-'
        // }
      }

      return "";
    },
    getTimeValuesByDurationDate(clockInTime, clockOutTime) {
      if (clockInTime && clockOutTime) {

        const timeDifferenceInMilliseconds =
          new Date(clockOutTime) - new Date(clockInTime);

        // Convert milliseconds to seconds
        const seconds = Math.floor(timeDifferenceInMilliseconds / 1000);

        // Convert seconds to minutes
        const minutes = Math.floor(seconds / 60);

        // Convert minutes to hours
        const hours = Math.floor(minutes / 60);

        // Convert hours to days
        const days = Math.floor(hours / 24);
        return `${hours % 24}hrs : ${minutes % 60}min : ${seconds % 60}sec`;

        // return `Time duration: ${days} days, ${hours % 24} hours, ${minutes % 60} minutes, ${seconds % 60} seconds`
      } else {
        return "";
      }
    },

    getStatusByClockInDate(allAttendenceList, targetDate) {
      let temp = [...this.attendanceList];

      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);

      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockInTime).setHours(0, 0, 0, 0);
        if (createdDateTime === targetDateTime) {
          return obj.status;
        }
      }

      return "Absent";
    },
    getIsAbsentByClockInDate(allAttendenceList, targetDate) {
      const temp = [...allAttendenceList];

      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockedDate).setHours(0, 0, 0, 0);
        if (createdDateTime === targetDateTime) {
          return obj.isAbsent;
        }
      }
    },
    async updateStudentAttendance() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        //this.viLoader = true;
        const response = await ViService.viXPut(
          `/contact/update/student/attendance/${this.updateAttendanceDetails._id}`,
          {
            status: this.updateAttendanceDetails.status,
          },
          userData.token
        );

        if (response.isSuccess) {
          this.closeUpdateModel();
          this.getStudentAttendanceList();
          this.$toasted.success(response.message);
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    checkAvaiable(data){
     if(this.dayList.length > 0){
      let details = this.dayList.find( x => x == data)
      if(details){
        return true
      }else{
        return false
      }
     }else{
      return false
     }
    },
    async getAccountDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
       // this.viLoader = true;
        const response = await ViService.viXGet(
          "/account/getAccountSettingConfigDetails?accountId=" +
            userData.userDetails.account._id,
          userData.token
        );

        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);

          if (details.workingDayList && details.workingDayList.length > 0) {
            this.dayList = []

            const filteredObjects = details.workingDayList.filter(obj => obj.isselected);

            if(filteredObjects.length){
              this.dayList = filteredObjects.map(obj => obj.name);
            //this.dayList = selectedValues
            //return selectedValues.join(', ')
          }
        }

         
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
       // this.viLoader = false;
      }
    },
    showUpdateModal(data) {
      this.updateAttendanceDetails = {};
      this.updateAttendanceDetails = data;
      this.$bvModal.show("updateModel");
    },
    closeUpdateModel() {
      this.updateAttendanceDetails = {};
      this.$bvModal.hide("updateModel");
    },
    async getStudentAttendanceList() {
      if (this.selectClass && this.selectSection && this.selectDate) {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          const response = await ViService.viXGet(
            `/contact/attendance/getStudentList?class=${this.selectClass}&classroom=${this.selectSection}&selectedDate=${this.selectDate}`,
            userData.token
          );

          if (response.isSuccess) {
            const resultData = secureUI.secureGet(response.data);

            this.studentAttendanceList = [];
            this.studentAttendanceDetails = [];
            let studentlist = resultData.studentList;

            this.studentCount = studentlist.length;

            if (studentlist.length > 0) {
              for (let x of studentlist) {
                
                if (x.attendance && x.attendance.isDailyAttendance) {
                  this.studentAttendanceList.push({
                    _id: x.attendance._id,
                    lastName: x.attendance.lastName,
                    firstName: x.attendance.firstName,
                    status: x.attendance.status,
                    isPresent: x.attendance.isPresent || false,
                    isDailyAttendance: x.attendance.isDailyAttendance,
                  });
                }
              }
           
             
              if (
                resultData.studentAttendanceData.length !=
                this.studentAttendanceList.length
              ) {
                this.isDailyAttendance = true;

                for (let z of studentlist) {
                  if (z.attendance) {
                    // }
                    //for(let y of resultData.studentAttendanceData){
                    this.studentAttendanceDetails.push({
                      _id: z.attendance._id,
                      firstName: z.firstName,
                      lastName: z.lastName,
                      status: z.attendance.status,
                      isPresent:
                        z.attendance.status == "Present" ? true : false,
                      student: z._id,
                      class: this.selectClass,
                      classSection: this.selectSection,
                      attendanceDate: this.selectDate,
                    });
                  } else {
                    this.studentAttendanceDetails.push({
                      _id: null,
                      firstName: z.firstName,
                      lastName: z.lastName,
                      status: "Present",
                      isPresent: true,
                      student: z._id,
                      class: this.selectClass,
                      classSection: this.selectSection,
                      attendanceDate: this.selectDate,
                    });
                  }
                }
              } else if (
                resultData.studentAttendanceData.length == 0 &&
                this.studentAttendanceList.length == 0
              ) {
                this.isDailyAttendance = true;
                for (let z of studentlist) {
                  this.studentAttendanceDetails.push({
                    _id: null,
                    firstName: z.firstName,
                    lastName: z.lastName,
                    status: "Present",
                    isPresent: true,
                    student: z._id,
                    class: this.selectClass,
                    classSection: this.selectSection,
                    attendanceDate: this.selectDate,
                  });
                }
              } else {
                this.isDailyAttendance = false;
              }
            } else {
              this.isDailyAttendance = false;
            }

            //  else{

            //  }

            // if(this.selectClass && this.selectSection && this.selectDate){

            // studentAttendanceDetails
            //studentAttendanceCount


            // this.$toasted.success(response.message);
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
        }
      }
    },
    async getOverallAttendanceList() {
    //  if (this.selectClass && this.selectSection && this.selectDate) {
        let userData = secureUI.sessionGet("user");
      

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          const response = await ViService.viXGet(
            `/contact/attendance/getOverallAttendanceList?class=${this.overallSelectedClass}&classroom=${this.overallSelectedSection}&month=${this.overallSelectMonth}&search=${this.searchWords}&skip=${this.skip}&limit=${this.limit}&isCount=${this.countAll}`,
            userData.token
          );

          if (response.isSuccess) {
            const resultData = secureUI.secureGet(response.data);
          this.allOverallAttendanceList = resultData.studentList
         

            if(Object.keys(resultData.studentList).length > 0){
             

             this.lastPageNumber = Math.ceil(Object.keys(resultData.studentList).length/this.showLimitCount);

            }

         
            var result = Object.entries(resultData.studentList);
            
            let ss = result.slice(0, this.showLimitCount)
         
       
             const obj = Object.fromEntries(ss);
             this.overallAttendanceList = obj
          } else {
            this.$toasted.error(response.message);
            if (response.message == "Your session has expired, please login") {
              localStorage.removeItem("user");
              this.$router.push("/login");
            }
          }
       
      }
    },
    
    
    openAttendancePopUp() {
      this.$bvModal.show("addAttendancePopUp");
    },

    async generateandApprove() {
      let userData = secureUI.sessionGet("user");

      const response = await ViService.viXPost(
        "/contact/save/daily/attendance/student",
        this.studentAttendanceDetails,
        userData.token
      );

      if (response.isSuccess) {
        // this.$bvModal.hide("generateSection");
        this.closeAttendancePopUp();
        this.getStudentAttendanceList();
        this.$toasted.success(response.message);
      } else {
        this.$toasted.error(response.message);
        if (response.message == "Your session has expired, please login") {
          localStorage.removeItem("user");
          this.$router.push("/login");
        }
      }
    },
    async updateDailyAttendance() {
      let userData = secureUI.sessionGet("user");

      const response = await ViService.viXPost(
        "/contact/update/daily/attendance/student",
        this.studentAttendanceList,
        userData.token
      );

      if (response.isSuccess) {
        // this.$bvModal.hide("generateSection");
        this.closeAttendancePopUp();
        this.getStudentAttendanceList();
        this.$toasted.success(response.message);
      } else {
        this.$toasted.error(response.message);
        if (response.message == "Your session has expired, please login") {
          localStorage.removeItem("user");
          this.$router.push("/login");
        }
      }
    },
    closeAttendancePopUp() {
      this.$bvModal.hide("addAttendancePopUp");
    },
    async confirmDelete() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viDelete(
          `/user/attendance/${this.deleteId}`,
          userData.token
        );

        if (response.isSuccess) {
          this.$toasted.success(response.message);
          this.getStaffAttendanceList();
          this.closeDeletePopUp();
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
      this.viLoader = false;
    },
    getPreviousMonths() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      for (let i = currentMonth; i >= 0; i--) {
        this.previousMonths.push({
          label: months[i],
          value: i,
        });
      }
      this.overAllPreviousMonths = [...this.previousMonths]

      this.overallSelectMonth =  this.previousMonths[0].value
      this.getOverallAttendanceList()
    },
    selectedOption(value) {
      if (value) {
        this.selectedStaff = value
      } else {
        (this.selectedMonth = null), (this.attendanceList = []);
      }
    },
    async getStaffAttendanceList() {
      this.attendanceList = [];

      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
        this.$router.push("/login");
      } else {
        const response = await ViService.viXPost(
          "/user/staff/admin/view/attendance/list",
          {
            skip: 0,
            limit: 1000,
            currentMonth: this.selectedMonth,
            staffId: this.selectedStaff,
          },
          userData.token
        );
        // const response = await ViService.viXGet(`/contact/getOnlineCertificateRequestDetails?studentId=${this.selectedStudent}`,userData.token);

        if (response.isSuccess) {
          this.attendanceList = secureUI.secureGet(response.data) || [];
          
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      }
    },
    getSelectedMonthAttendanceList(selectedMonth) {
      
      if(selectedMonth){
        this.changeDateList(selectedMonth)
      }else{
        this.dates = []
      }
      
    },
    async getStaffList(search, loading) {
      let userData = secureUI.sessionGet("user");

      if (search && search != "" && search != null && search != null) {
        const response = await ViService.viXGet(
          `/teacher/getStaffSearchList?search=${search}`,
          userData.token
        );

        // const response = await ViService.viXGet(`/common/allschool?skip=${this.skip}&limit=${this.limit}&search=${search}&isCount=${this.countAll}`);
        if (response.isSuccess) {
          const resultData = secureUI.secureGet(response.data);
          this.staffList = resultData;
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
      } else {
        this.accountList = [];
        this.showOption = false;
      }
    },
    previousRoute() {
      let data = localStorage.getItem("previousTab");
      localStorage.setItem("activeTab", data);
      localStorage.setItem("previousTab", data);
      this.$router.go(-1);
    },
  },
};
</script>
