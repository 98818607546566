<template>
  <div id="page-content" style="margin-top: 5%">
    <div class="webNew-dispflex">
       
       <div class="backbox" style="margin-left: 3px;">
     <div class="back-arrow" @click="previousRoute">
       <i class="fa fa-arrow-left" style="padding-top: 11px;padding-left: 11px;"></i>
    
     
     </div>

       </div>
       <h4 class="hdrtitle backHeading" style="margin-top: 0px;">
        Attendance Report
       </h4>
     </div>
    <div class="row" style="padding: 10px">
      <div class="col-lg-12">
        <div class="widjet">
          <div class="row">
            <div class="col-lg-5">
              <!-- <div style="padding: 10px">
                <h3>Attendance Report</h3>
              </div> -->
            </div>

            <div class="col-lg-3">
              <span class="has-float-label" style="margin-top: 3%;">
                <select
                  class="form-control form-input"
                  id="typeList"
                  v-model="selectedMonth"
                  @change="getSelectedMonthAttendanceList(selectedMonth)"
                >
                  <option
                    v-for="(item, index) in previousMonths"
                    :key="index"
                    :value="item.value"
                  >
                    {{ item.label }}
                  </option>
                </select>
                <label for="clslistid">Month </label>
              </span>
            </div>

            <!-- <div class="col-lg-1" style="margin: auto; text-align: center">
              <button
                class="btn gry_bg mpopup"
                @click.prevent="proceedForNewAttendance"
              >
                <i class="fas fa-plus"></i>
              </button>
            </div> -->
          </div>

          <!-- <div class="row">
            <div class="col-lg-12">
              <div style="padding: 10px">
                <div class="restable">
                  <div class="widjetcontent" v-if="attendanceList.length > 0">
                    <div class="restable">
                      <div class="resrow resheader">
                        <div class="cell">S.No</div>
                        <div class="cell">Clock In Time</div>
                        <div class="cell">Clock Out Time</div>
                        <div class="cell">Is Absent?</div>
                        <div class="cell">Notes</div>
                        <div class="cell">Action</div>
                      </div>

                      <div
                        class="resrow"
                        v-for="(attd, index) in attendanceList"
                        :key="index"
                      >
                        <div class="cell" data-title="S.No">
                          {{ index + 1 }}
                        </div>
                        <div class="cell" data-title="Clock In Time">
                          {{ attd.clockInTime | dateTimeFormat }}
                        </div>
                        <div class="cell" data-title="Clock Out Time">
                          {{ attd.clockOutTime | dateTimeFormat }}
                        </div>
                        <div class="cell" data-title="Notes">
                          {{ attd.isAbsent ? "Absent" : "Present" }}
                        </div>
                        <div class="cell" data-title="Notes">
                          {{ attd.notes }}
                        </div>
                        <div class="cell" data-title="Action">
                          <button
                            class="btn round orange tooltipt"
                            @click.prevent="proceedForEditAttendance(index)"
                          >
                            <i class="fas fa-edit"></i
                            ><span class="tooltiptext">Edit</span>
                          </button>
                          <button
                            class="btn round red tooltipt"
                            @click.prevent="proceedDeletePopup(index)"
                          >
                            <i class="far fa-trash-alt"></i>
                            <span class="tooltiptext">Delete</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="restable" v-if="attendanceList.length == 0">
                    <div class="resrow resheader">
                      <div class="cell">S.No</div>
                      <div class="cell">Clock In Time</div>
                      <div class="cell">Clock Out Time</div>
                      <div class="cell">Created By</div>
                      <div class="cell">Action</div>
                    </div>

                    <div class="cell" aria-colspan="5">No data found</div>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <!-- <li v-for="date in dates" :key="date">{{ date }}</li> -->

          <table class="table birthday_table text-center">
            <thead>
              <tr>
                <th scope="col"  style="text-align:center">Day</th>
                <th scope="col" style="text-align:center">Date</th>
                <th scope="col" style="text-align:center">Clock In Time</th>
                <th scope="col" style="text-align:center">Clock Out Time</th>
                <th scope="col" style="text-align:center">Duration</th>
                <th scope="col" style="text-align:center">Status</th>
                <th scope="col" style="text-align:center">Action</th>
              </tr>
            </thead>
            
            <tbody>
              <tr  v-for="(data, index) in dates" :key="index">
                <td :style="!checkAvaiable(data.day) ? 'cursor: not-allowed;background-color: #e3ecff;color: darkgray;text-align:center' : 'text-align:center'">{{ data.day || '' }}</td>
                <td :style="!checkAvaiable(data.day) ? 'cursor: not-allowed;background-color: #e3ecff;color: darkgray;text-align:center' : 'text-align:center'">{{ data.createdDate }}</td>
               

                <td :style="!checkAvaiable(data.day) ? 'cursor: not-allowed;background-color: #e3ecff;color: darkgray;text-align:center' : 'text-align:center'"> 
                  <span v-if="getTimeValuesByClockInDate(attendanceList, data.createdDate)">
                    <span v-if="getTypeByClockInDate(attendanceList,data.createdDate) == 'Home'"> 
                        <a href="javascript:void(0);" style="color: cornflowerblue;" @click.prevent="viewMap(attendanceList, data.createdDate)">{{  getTimeValuesByClockInDate(attendanceList, data.createdDate) }}</a>

                     </span>
                    <span v-else>{{  getTimeValuesByClockInDate(attendanceList, data.createdDate)   }}</span>
                  </span>
                  <span v-else>-</span>
                </td>
                
                <td :style="!checkAvaiable(data.day) ? 'cursor: not-allowed;background-color: #e3ecff;color: darkgray;text-align:center' : 'text-align:center'"> 

                  <span v-if="getTimeValuesByClockOutDate(attendanceList, data.createdDate)">
                    <span v-if="getTypeByClockOutDate(attendanceList,data.createdDate) == 'Home'"> 
                        <a href="javascript:void(0);" style="color: cornflowerblue;" @click.prevent="viewClockOutMap(attendanceList, data.createdDate)">{{  getTimeValuesByClockOutDate(attendanceList, data.createdDate) }}</a>

                     </span>
                    <span v-else>{{  getTimeValuesByClockOutDate(attendanceList, data.createdDate)   }}</span>
                  </span>
                  <span v-else>-</span>

                  <!-- {{ getTimeValuesByClockOutDate(attendanceList,data.createdDate) ? getTimeValuesByClockOutDate(attendanceList,data.createdDate) : '-' }} -->
                
                </td>
                <td :style="!checkAvaiable(data.day) ? 'cursor: not-allowed;background-color: #e3ecff;color: darkgray;text-align:center' : 'text-align:center'" v-if="getTimeValuesByClockInDate(attendanceList, data.createdDate) && getTimeValuesByClockOutDate(attendanceList, data.createdDate)">{{ getTimeValuesByDurationDate(getTimeDurationByClockInDate(attendanceList, data.createdDate),getTimeDurationByClockOutDate(attendanceList, data.createdDate)) }}</td>
                <td :style="!checkAvaiable(data.day) ? 'cursor: not-allowed;background-color: #e3ecff;color: darkgray;text-align:center' : 'text-align:center'" v-else>-</td>
               
                <td :style="!checkAvaiable(data.day) ? 'cursor: not-allowed;background-color: #e3ecff;color: darkgray;text-align:center' : getStatusByClockInDate(attendanceList,data.createdDate) == 'Present' ? 'text-align:center;color: #0aed06;' : getStatusByClockInDate(attendanceList,data.createdDate) == 'Absent' ? 'text-align:center;color: red;' : 'text-align:center;'" >{{ getStatusByClockInDate(attendanceList,data.createdDate) }}</td>

                <td :style="!checkAvaiable(data.day) ? 'cursor: not-allowed;background-color: #e3ecff;color: darkgray;text-align:center' : 'text-align:center'" >

                  <b-dropdown
                              id="dropdown-1"
                              text=""
                              right
                              style="margin-right: 3px"
                            >
                             
                              <b-dropdown-item style="width: 28px !important;height: 28px !important;" :disabled="getStatusByClockInDate(attendanceList,data.createdDate) == 'Pending Approval' || getStatusByClockInDate(attendanceList,data.createdDate) == 'Leave' || !checkAvaiable(data.day)"
                               @click.prevent="openModal(data)"  >Regularize </b-dropdown-item>
                             
                             
                            </b-dropdown>
                  <!-- <button style="background: #2954A2;
    border: 1px solid rgb(206, 212, 218);
    border-radius: 1.25rem;
    width: 102px;
    color: white;" :disabled="getStatusByClockInDate(attendanceList,data.createdDate) == 'Pending Approval' || getStatusByClockInDate(attendanceList,data.createdDate) == 'Leave' || !checkAvaiable(data.day)" @click.prevent="openModal(data)">Regularize</button> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <b-modal id="modalOne" content-class="md_content" class="modal">
      <!-- <button
        style="
          border: 0;
          background: transparent;
          position: absolute;
          top: 10px;
          right: 10px;
        "
        @click.prevent="closeAttendance"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </button> -->

      <div class="row">
        <div class="col-md-12">
          <div>
            <h4>Attendance Details</h4>
          </div>

          <div>
            <form
              data-vv-scope="attendance"
              id="attendance-fomr"
              accept-charset="utf-8"
            >
              <div class="form-row">
              
                <div  class="form-group input-group col-3 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <input
                      disabled
                      type="date"
                      name="clkin"
                      v-model="attendance.clockInTime"
                      class="form-control form-input"
                    />
                   
                    <label for="clockInTime" 
                      >Clock In Date <span class="required">*</span></label
                    >
                  </span>
                </div>
                <div  class="form-group input-group col-2 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <input
                      @change="checkClockInTime(attendance.clockedInTimeValue)"
                      type="time"
                      v-model="attendance.clockedInTimeValue"
                      class="form-control form-input"
                    />
                   
                    <label for="clockInTime" 
                      >Clock In Time <span class="required">*</span></label
                    >
                  </span>
                </div>
              </div>
                <div class="form-row">
                <div  class="form-group input-group col-3 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <input
                     disabled
                      type="date"
                      name="clockOutTime"
                      v-model="attendance.clockOutTime"
                      class="form-control form-input"
                    />
                    <label for="clockOutTime"> Clock Out Date <span class="required">*</span></label>
                  </span>
                </div>
                <div  class="form-group input-group col-2 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <!-- :max="attendance.clockedInTimeValue" -->
                    <input
                      type="time"
                      @change="checkClockOutTime(attendance.clockedInTimeValue,attendance.clockedOutimeValue)"
                      v-model="attendance.clockedOutimeValue"
                      class="form-control form-input"
                    />
                    <label for="clockOutTime"> Clock Out Time <span class="required">*</span></label>
                  </span>
               
              </div>
            </div>
                <div class="form-row">
                <div class="form-group input-group col-12 mt-md-3">
                  <span class="has-float-label">
                    <textarea
                      id="notes"
                      class="form-control form-input"
                      placeholder="Notes"
                      style="min-height: 90px; resize: none"
                      v-model="attendance.notes"
                    ></textarea>
                    <label for="notes">Notes <span class="required">*</span></label>
                  </span>
                </div>
              </div>
              
            </form>
          </div>

          <div class="text-right">
            <div class="dk_iconsml">
              <button
                id="updbtn"
                type="button"
                :disabled="!attendance.clockedInTimeValue || !attendance.clockedOutimeValue || !attendance.clockInTime || !attendance.clockOutTime || !attendance.notes"
                class="btn btnsml"
                @click.prevent="updateAttendance"
              >
                  <span>Save</span>
              </button>
            
              <button
                id="clbtn"
                type="button"
                class="btn btncl"
                @click.prevent="closeAttendance"
              >
                   <span>Cancel</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <b-modal id="confirmationPopup">
      <button type="button" class="close"  @click.prevent="closeDeletePopUp">×</button>

      <!-- <div class="modal-content"> -->
      <div class="contact-form">
        <!-- <span class="close">&times;</span> -->
        <h2>Delete Confirmation</h2>
        <br />
        Are you sure you want to delete? Once deleted you cannot able to revert
        back the changes.
        <div
          class="pt-3 text-center dk_iconsml"
          style="border-top: 1px solid #e5e5e5"
        >
          <button
            type="button"
            class="btn btnsml"
            id="svbtn"
            @click.prevent="confirmDelete"
          >
              <span>Confirm</span>
          </button>
          <button
            @click.prevent="closeDeletePopUp"
            type="button"
            class="btn btncl clsmdl"
            id="clbtn"
          >
               <span>Cancel</span>
          </button>
        </div>
      </div>
      <!-- </div> -->
    </b-modal>

    <!-- <b-modal id="showModal">
      <h3>Edit Attendance</h3>

      <div class="form-group input-group col-12 pr-md-3 mt-3">
                  <span class="has-float-label">
                    <input
                      name="clockInTime"
                      type="datetime-local"
                      max="9999-12-31"
                      v-validate="'required'"
                      v-model="clockInTime"
                      class="form-control form-input"
                      :disabled="attendance.isAbsent"
                    />
                    <label
                      for="clockInTime"
                      class="vi-error"
                      style="top: -1em"
                      v-if="errors.first('clockInTime')"
                      >Please enter clock in time
                    </label>
                    <label for="clockInTime" v-else
                      >Clock In Time <span class="required">*</span></label
                    >
                  </span>
                </div>
                
       <label for="clock-in-time">Clock In Time:</label>
      <input id="clock-in-time" v-model="clockInTime" type="text" />

      <label for="clock-out-time">Clock Out Time:</label>
      <input id="clock-out-time" v-model="clockOutTime" type="text" />

      <button @click="saveModal">Save</button>
      <button @click="cancelModal">Cancel</button>
    </b-modal> -->
  </div>
</template>

<script>
import ViService from "@/services/ViService";
import secureUI from "../utils/secureUI";
import { format, utcToZonedTime } from "date-fns-tz";

export default {
  name: "ViAttendance",
  data() {
    return {
      skip: 0,
      limit: 50,
      isEdit: false,
      deleteIndex: null,
      minimumDate: null,
      editIndex: 0,
      attendance: {},
      attendanceList: [],
      previousMonths: [],
      dates: [],
      selectedIndex: null,
      clockInTime: "",
      clockOutTime: "",
      selectedMonth: "",
      currentMonth: new Date().getMonth() + 1,
      dayList: [],
     // const weekday = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
      months: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ],
    };
  },
  created() {
   
    this.getAttendanceList();
    this.getPreviousMonths();
    this.initMethod()
   
  },
  methods: {
    previousRoute(){
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        if (userData.userDetails.profile.name == "School Admin") {
          this.$router.push("/school/admin/home")
        }else{
          this.$router.push("/teacher/home")
        }
      }
    //  let data = localStorage.getItem('previousTab');
    //  localStorage.setItem("activeTab",data);
    //  localStorage.setItem("previousTab",this.$route.path);
    //   this.$router.push({ path: data });
    },
    async initMethod(){
      this.selectedMonth = this.months[new Date().getMonth()]
      await this.getAccountDetails();
      this.formDateList();
      // await navigator.geolocation.getCurrentPosition(
      //   (position) => {
      //     console.log("position",position)
      //     var ltde = position.coords.latitude.toFixed(6);
      //     var lngt = position.coords.longitude.toFixed(6);

      //     var mapUrl = "https://www.google.com/maps/place/" + ltde + "," + lngt;

      //     window.open(mapUrl, "_blank");

      //     //window.open(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=AIzaSyA0VSaHmsUM8If8jEipJAYJ66js87WD4Co`);
      //     //window.open(`https://maps.google.com/maps/search/?api=1&query=${ltde},${lngt}&key=AIzaSyA0VSaHmsUM8If8jEipJAYJ66js87WD4Co`);
      //     //window.open(`https://maps.google.com/maps/search/?api=1&query=${13.1513952},${80.2021637}&key=AIzaSyA0VSaHmsUM8If8jEipJAYJ66js87WD4Co`);
      //     // window.open(`https://maps.googleapis.com/maps/api/js?center=${position.coords.latitude},${position.coords.longitude}&zoom=14&size=400x300&sensor=false&key=AIzaSyA0VSaHmsUM8If8jEipJAYJ66js87WD4Co`)
      //         })
    },
    checkAvaiable(data){
     if(this.dayList.length > 0){
      let details = this.dayList.find( x => x == data)
      if(details){
        return true
      }else{
        return false
      }
     }else{
      return false
     }
    },
    checkClockInTime(data){
      this.attendance.clockedOutimeValue = ''

    },
    checkClockOutTime(inTime,outTime){

      // // Get the values of the time fields
      //       var inputTime1 = timeInput1.value;
      //       var inputTime2 = timeInput2.value;

            // Parse the input times into Date objects
            var parsedInputTime1 = new Date("1970-01-01T" + inTime + ":00");
            var parsedInputTime2 = new Date("1970-01-01T" + outTime + ":00");

            // Compare the two input times
            if (parsedInputTime1 < parsedInputTime2) {
              console.log("The first time is earlier than the second time")

            } else if(parsedInputTime1 > parsedInputTime2) {
              console.log("The first time is later than the second time")

            } 
            // else {
            //     document.getElementById("result").textContent = "The two times are the same.";
            // }
    },
    async getAttendanceList() {
      try {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
        } else {
          const response = await ViService.viXPost(
            "/user/attendance/list",
            {
              skip: this.skip,
              limit: this.limit,
              currentMonth: this.currentMonth,
            },
            userData.token
          );

          if (response.isSuccess) {
            this.attendanceList = secureUI.secureGet(response.data) || [];
          } else {
            console.log("in else ==> ");
          }
        }
      } catch (err) {
        console.log("in catch error ==> ", err);
      }
    },
    proceedForNewAttendance() {
      this.isEdit = false;
      this.attendance.isAbsent = false;
      this.attendance.clockOutTime = null;
      this.attendance.clockInTime = null;
      this.attendance.notes = "";
      this.$bvModal.show("modalOne");
    },
    proceedForEditAttendance(index) {
      this.isEdit = true;
      this.editIndex = index;
      this.attendance.isAbsent = this.attendanceList[index].isAbsent || false;
      this.attendance.clockOutTime = this.attendanceList[index].clockOutTime
        ? new Date(this.attendanceList[index].clockOutTime)
        : null;
      this.attendance.clockInTime = this.attendanceList[index].clockInTime
        ? new Date(this.attendanceList[index].clockInTime)
        : null;
      this.attendance.notes = this.attendanceList[index].notes || "";

      this.$bvModal.show("modalOne");
    },
    proceedDeletePopup(index) {
      this.deleteIndex = index;
      this.$bvModal.show("confirmationPopup");
    },
    absentChange() {
      this.attendance.clockInTime = null;
      this.attendance.clockOutTime = null;
    },
    async getAccountDetails() {
      let userData = secureUI.sessionGet("user");

      if (!userData) {
        this.$toasted.error("Please login and do the action");
      } else {
        this.viLoader = true;
        const response = await ViService.viXGet(
          "/account/getAccountSettingConfigDetails?accountId=" +
            userData.userDetails.account._id,
          userData.token
        );

        if (response.isSuccess) {
          const details = secureUI.secureGet(response.data);

          if (details.workingDayList && details.workingDayList.length > 0) {
            this.dayList = []

            const filteredObjects = details.workingDayList.filter(obj => obj.isselected);

            if(filteredObjects.length){
              this.dayList = filteredObjects.map(obj => obj.name);
            //this.dayList = selectedValues
            //return selectedValues.join(', ')
          }
        }

         
        } else {
          this.$toasted.error(response.message);
          if (response.message == "Your session has expired, please login") {
            localStorage.removeItem("user");
            this.$router.push("/login");
          }
        }
        this.viLoader = false;
      }
    },
    async addAttendance() {
      try {
        let isFormValid = false;

        await this.$validator.validateAll("modalOne").then((result) => {
          isFormValid = result;
        });

        if (isFormValid) {
          let userData = secureUI.sessionGet("user");

          if (!userData) {
            this.$toasted.error("Please login and do the action");
            this.$router.push("/login");
          } else {
            const body = {
              clockInTime: this.attendance.clockInTime,
              clockOutTime: this.attendance.clockOutTime,
              notes: this.attendance.notes || "",
              isAbsent: this.attendance.isAbsent || false,
              type: 'Attendance Regularization',
              clockedDate: this.attendance.clockInTime
            };

            const response = await ViService.viXPost(
              "/home/clockin/save",
              body,
              userData.token
            );

            if (response.isSuccess && response.data) {
              this.attendanceList.push({ ...response.data });
              this.$bvModal.hide("modalOne");
              this.$toasted.success("Created Successfully");
            } else {
              this.$toasted.error(response.message);
            }
          }
        }
      } catch (err) {
        this.$toasted.error(
          err && err.message ? err.message : "Internal Server Error"
        );
      }
    },
    async updateAttendance() {
      try {
        let isFormValid = false;

        await this.$validator.validateAll("modalOne").then((result) => {
          isFormValid = result;
        });

        if (isFormValid) {
          let userData = secureUI.sessionGet("user");

          if (!userData) {
            this.$toasted.error("Please login and do the action");
            this.$router.push("/login");
          } else {
           
           
            this.attendance.clockedInTimeLocalValue = this.attendance.clockedInTimeValue
            this.attendance.clockedOutTimeLocalValue = this.attendance.clockedOutimeValue
            this.attendance.newClockedInTimeLocalValue = this.attendance.clockedInTimeValue
            this.attendance.newClockedOutTimeLocalValue = this.attendance.clockedOutimeValue
              
            const response = await ViService.viXPut(
              `/user/attendance/regularize`,
              this.attendance,
              userData.token
            );

            if (response.isSuccess) {
              this.attendance = {}
              this.$bvModal.hide("modalOne");
              this.$toasted.success("Regularize Updated Successfully");
              this.getAttendanceList();
            } else {
              this.$toasted.error(response.message);
            }
          }
        }
      } catch (err) {
        this.$toasted.error(
          err && err.message ? err.message : "Internal Server Error"
        );
      }
    },
    async confirmDelete() {
      try {
        let userData = secureUI.sessionGet("user");

        if (!userData) {
          this.$toasted.error("Please login and do the action");
          this.$router.push("/login");
        } else {
          const response = await ViService.viDelete(
            `/user/attendance/${this.attendanceList[this.deleteIndex]._id}`,
            userData.token
          );

          if (response.isSuccess) {
            this.attendanceList.splice(this.deleteIndex, 1);

            this.$bvModal.hide("confirmationPopup");
            this.$toasted.success("Deleted Successfully");
          } else {
            this.$toasted.error(response.message);
          }
        }
      } catch (err) {
        this.$toasted.error(
          err && err.message ? err.message : "Internal Server Error"
        );
      }
    },
    closeAttendance() {
      this.attendance = {}
      this.$bvModal.hide("modalOne");
    },
    closeDeletePopUp() {
      this.$bvModal.hide("modalOne");
    },
    async formDateList() {
      const currentDate = new Date();
      
      const firstDayOfMonth = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        1
      ); // Get the first day of the month

      this.currentMonth = currentDate.getMonth() + 1;

      while (currentDate >= firstDayOfMonth) {
      //  if(currentDate.getDay() == 0){
          const formattedDate = currentDate.toLocaleString("en-US", {
          weekday: "long",
          month: "long",
          day: "numeric",
        }); // Format the date as "Monday - May 29"

        const dayValue = currentDate.toLocaleString("en-US", {
          weekday: "long"
        }); // Format the date as "Monday - May 29"
        
        const currentYear = currentDate.getFullYear();
        const currentMonth = String(currentDate.getMonth() + 1).padStart(
          2,
          "0"
        ); // Months are zero-based, so we add 1
        const currentDay = String(currentDate.getDate()).padStart(2, "0");
        
       // if(new Date(formattedDate).getDay() != 1){
        this.dates.push({
          day: dayValue || '',
          date: formattedDate,
          createdDate: `${currentYear}-${currentMonth}-${currentDay}`,
          clockInTime: "", // Replace with the actual clockInTime value
          clockOutTime: "", // Replace with the actual clockOutTime value
        }); // Add the formatted date and other data to the array
     // }

        currentDate.setDate(currentDate.getDate() - 1); // Decrement the date by one day
        }
       
      //}
    },
    getTimeValuesByClockInDate(allAttendenceList, targetDate) {

      const temp = [...allAttendenceList]

      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
      for (const obj of temp) {

        const createdDateTime = new Date(obj.clockInTime).setHours(0, 0, 0, 0);

        if (createdDateTime === targetDateTime) {
          const clockInDateTime = new Date(obj.clockInTime);
          if(obj.status == 'Leave' || (obj.status == 'Pending Approval' && obj.previousStatus == 'Pending Approval')){
            return '';
          }else{
           // getTimeValuesByClockInDate
            return obj.clockedInTimeLocalValue;
          }
          
        }
        // else{
        //   return '-'
        // }
      }

      return "";
    },
    viewMap(allAttendenceList, targetDate) {

    const temp = [...allAttendenceList]

    const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
    for (const obj of temp) {

      const createdDateTime = new Date(obj.clockInTime).setHours(0, 0, 0, 0);

      if (createdDateTime === targetDateTime) {
        const clockInDateTime = new Date(obj.clockInTime);
        if(obj.status == 'Leave' || (obj.status == 'Pending Approval' && obj.previousStatus == 'Pending Approval')){
          return '';
        }else{
              var ltde = obj.clockinLocation.latitude.toFixed(6);
              var lngt = obj.clockinLocation.longitude.toFixed(6);

              var mapUrl = "https://www.google.com/maps/place/" + ltde + "," + lngt;

              window.open(mapUrl, "_blank");
        }
    
  }
  // else{
  //   return '-'
  // }
}

return "";
    },
    viewClockOutMap(allAttendenceList, targetDate) {

    const temp = [...allAttendenceList]

    const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
    for (const obj of temp) {

      const createdDateTime = new Date(obj.clockOutTime).setHours(0, 0, 0, 0);

      if (createdDateTime === targetDateTime) {
        const clockInDateTime = new Date(obj.clockOutTime);
        if(obj.status == 'Leave' || (obj.status == 'Pending Approval' && obj.previousStatus == 'Pending Approval')){
          return '';
        }else{
              var ltde = obj.clockoutLocation.latitude.toFixed(6);
              var lngt = obj.clockoutLocation.longitude.toFixed(6);

              var mapUrl = "https://www.google.com/maps/place/" + ltde + "," + lngt;

              window.open(mapUrl, "_blank");
        }

    }
    // else{
    //   return '-'
    // }
    }

    return "";
    },
    
    getTimeValuesByClockOutDate(allAttendenceList, targetDate) {
      const temp = [...allAttendenceList]

      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);

      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockOutTime).setHours(0, 0, 0, 0);

        if (createdDateTime === targetDateTime) {
          const clockOutDateTime = new Date(obj.clockOutTime);
          if(obj.status == 'Leave' || (obj.status == 'Pending Approval' && obj.previousStatus == 'Pending Approval')){
            return '';
          }else{
            return obj.clockedOutTimeLocalValue;
          }
         // return clockOutDateTime.toLocaleTimeString();
        }
       
      }

      return "";
    },
    getTimeDurationByClockInDate(allAttendenceList, targetDate) {
      const temp = [...allAttendenceList]

      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockInTime).setHours(0, 0, 0, 0);
        if (createdDateTime === targetDateTime) {
          return obj.clockedInTimeValue;
         // return clockInDateTime.toLocaleTimeString();
        }
        // else{
        //   return '-'
        // }
      }

      return "";
    },
    getTimeDurationByClockOutDate(allAttendenceList, targetDate) {
      const temp = [...allAttendenceList]
      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);

      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockOutTime).setHours(0, 0, 0, 0);

        if (createdDateTime === targetDateTime) {
          return obj.clockedOutimeValue
          //return clockOutDateTime.toLocaleTimeString();
        }
        // else{
        //   return '-'
        // }
      }

      return "";
    },
    getTimeValuesByDurationDate(clockInTime,clockOutTime){
     
      if(clockInTime && clockOutTime){
        var hour1 = clockInTime.split(':')[0];
          var hour2 = clockOutTime.split(':')[0];
          var min1 = clockInTime.split(':')[1];
          var min2 = clockOutTime.split(':')[1];

          var diff_hour = hour2 - hour1;
          var diff_min = min2 - min1;
          if (diff_hour<0) {
              diff_hour+= 24;
          }
          if (diff_min<0) {
              diff_min+=60;
              diff_hour--;
          } else if(diff_min>=60){
              diff_min-=60;
              diff_hour++;
          }
        // const timeDifferenceInMilliseconds = new Date(this.clockOutBody.clockOutTime) - new Date(this.clockOutBody.clockInTime);

        // const seconds = Math.floor(timeDifferenceInMilliseconds / 1000);
        // const minutes = Math.floor(seconds / 60);
        // const hours = Math.floor(minutes / 60);
        // const days = Math.floor(hours / 24);

        return `${diff_hour % 24}hrs : ${diff_min % 60}mins`;

        }else{
          return ''
        }
    },
    
    getStatusByClockInDate(allAttendenceList, targetDate) {
      let temp = [...allAttendenceList]

      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
      

      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockInTime).setHours(0, 0, 0, 0);
        if (createdDateTime === targetDateTime) {
          return obj.status;
        }
      }

      return "Absent";
    },
    getTypeByClockInDate(allAttendenceList, targetDate) {
      let temp = [...allAttendenceList]

      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
      

      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockInTime).setHours(0, 0, 0, 0);
        if (createdDateTime === targetDateTime) {
          return obj.type;
        }
      }

      return "Home";
    },
    getTypeByClockOutDate(allAttendenceList, targetDate) {
      let temp = [...allAttendenceList]

      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
      

      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockOutTime).setHours(0, 0, 0, 0);
        if (createdDateTime === targetDateTime) {
          return obj.type;
        }
      }

      return "Home";
    },
    getIsAbsentByClockInDate(allAttendenceList, targetDate) {
      const temp = [...allAttendenceList]

      const targetDateTime = new Date(targetDate).setHours(0, 0, 0, 0);
      for (const obj of temp) {
        const createdDateTime = new Date(obj.clockedDate).setHours(0, 0, 0, 0);
        if (createdDateTime === targetDateTime) {
          return obj.isAbsent;
        }
      }
    },
    openModal(data) {
      this.minimumDate = null
      this.attendance = {}

      let tempAttendanceList = [...this.attendanceList]

      if(this.getTimeValuesByClockInDate(tempAttendanceList, data.createdDate)){
       
      const targetDateTime = new Date(data.createdDate).setHours(0, 0, 0, 0);

      for (const obj of tempAttendanceList) {
        const createdDateTime = new Date(obj.clockInTime).setHours(0, 0, 0, 0);
        if(createdDateTime === targetDateTime) {
           this.attendance = obj

           var clockInDate = new Date(this.attendance.clockInTime);
           var clockInYear = clockInDate.getFullYear();
           var clockInMonth = String(clockInDate.getMonth() + 1).padStart(2, '0'); // Add 1 to month since it is zero-based
           var clockInDay = String(clockInDate.getDate()).padStart(2, '0');

           this.attendance.clockInTime = `${clockInYear}-${clockInMonth}-${clockInDay}`;
          // this.attendance.clockedInTimeValue = `13:30`
          //  this.attendance['clockedOutimeValue'] = null,
           
           if(this.attendance.clockOutTime){

           var clockOutDate = new Date(this.attendance.clockOutTime);
           var clockOutYear = clockOutDate.getFullYear();
           var clockOutMonth = String(clockOutDate.getMonth() + 1).padStart(2, '0'); // Add 1 to month since it is zero-based
           var clockOutDay = String(clockOutDate.getDate()).padStart(2, '0');
           this.attendance.clockOutTime = `${clockOutYear}-${clockOutMonth}-${clockOutDay}`;
          //  this.attendance['clockedInTimeLocalValue'] = null,
          //  this.attendance['clockedInTimeValue'] = null,
          //  this.attendance['clockedOutimeValue'] = null,
          //  this.attendance['clockedOutTimeLocalValue'] = null,


           }else{
            let date = new Date(data.createdDate);
            
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2,"0"); // Months are zero-based, so we add 1
            const day = String(date.getDate()).padStart(2, "0");

           // this.attendance.clockInTime =  `${year}-${month}-${day}`;
            this.attendance.clockOutTime =  `${year}-${month}-${day}`;
           }
          
        }
      }

      }else{

        let date = new Date(data.createdDate);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2,"0"); // Months are zero-based, so we add 1
        const day = String(date.getDate()).padStart(2, "0");

        this.attendance.clockInTime =  `${year}-${month}-${day}`;
        this.attendance.clockOutTime =  `${year}-${month}-${day}`;

        this.attendance['clockedInTimeLocalValue'] = null,
        this.attendance['clockedInTimeValue'] = null,
        this.attendance['clockedOutimeValue'] = null,
        this.attendance['clockedOutTimeLocalValue'] = null
     
      }

      this.$bvModal.show("modalOne");
    },
    saveModal() {
      this.$bvModal.show("modalOne");
    },
    cancelModal() {
      this.$bvModal.hide("modalOne");
    },
    getPreviousMonths() {
      const currentDate = new Date();
      const currentMonth = currentDate.getMonth();
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      // for (let i = currentMonth; i >= 0; i--) {
      //   this.previousMonths.push(months[i]);
      // }

      //this.previousMonths = months

      let getDetails = this.$store.getters.getCurrentAcademicYear;
           console.log("ddfdfd",getDetails)   

             // Parse the input
    let [startYear, startMonth] = getDetails.from.split('/').map(Number);
    let [endYear, endMonth] = getDetails.to.split('/').map(Number);

    // Helper array for month names
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    let result = [];

    // Loop from start to end
    while (startYear < endYear || (startYear === endYear && startMonth <= endMonth)) {
        result.push({
          label: `${monthNames[startMonth - 1]} ${startYear}`,
          value: monthNames[startMonth - 1]
        });
        
        // Move to the next month
        startMonth++;
        if (startMonth > 12) {
            startMonth = 1;
            startYear++;
        }
    }
    this.previousMonths =  result
console.log("result",result)
   // return result;
              // if (getDetails && getDetails.from) {
              //   this.contactDetails.academicYear = getDetails._id;
              //   //return getDetails.from + "-" + getDetails.to;
              // }

    },
    getSelectedMonthAttendanceList(selectedMonth) {
      this.dates = [];

      if (selectedMonth == this.months[new Date().getMonth()]) {
        this.formDateList();
      } else {
        // Convert month name to month index (0-based)
        const monthIndex = new Date(
          Date.parse(selectedMonth + " 1, 2023")
        ).getMonth();

        // Create a new Date object with the year and month
        const dateObj = new Date(2023, monthIndex);

        // Get the last day of the month
        const lastDate = new Date(
          dateObj.getFullYear(),
          dateObj.getMonth() + 1,
          0
        );

        this.currentMonth = dateObj.getMonth() + 1;
        // Get the first day of the month
        const firstDate = new Date(
          dateObj.getFullYear(),
          dateObj.getMonth(),
          1
        );

        const currentDate = new Date();
        const firstDayOfMonth = new Date(
          lastDate.getFullYear(),
          lastDate.getMonth(),
          1
        ); // Get the first day of the month

        while (lastDate >= firstDayOfMonth) {
          const formattedDate = lastDate.toLocaleString("en-US", {
            weekday: "long",
            month: "long",
            day: "numeric",
          }); // Format the date as "Monday - May 29"

          const dayValue = lastDate.toLocaleString("en-US", {
            weekday: "long"
          }); // Format the date as "Monday - May 29"

          const currentYear = lastDate.getFullYear();
          const currentMonth = String(lastDate.getMonth() + 1).padStart(2, "0"); // Months are zero-based, so we add 1
          const currentDay = String(lastDate.getDate()).padStart(2, "0");

          this.dates.push({
            day: dayValue || '',
            date: formattedDate,
            createdDate: `${currentYear}-${currentMonth}-${currentDay}`,
            clockInTime: "", // Replace with the actual clockInTime value
            
            clockOutTime: "", // Replace with the actual clockOutTime value
          }); // Add the formatted date and other data to the array

          lastDate.setDate(lastDate.getDate() - 1); // Decrement the date by one day
        }
      }
    },
  },
};
</script>

<style scoped>
.md_content {
  width: 50% !important;
}
</style>
